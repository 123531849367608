// import { Component } from '@angular/core';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { TweenMax } from 'gsap';
// import { FBXLoader } from '../../node_modules/three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three-full';
import { createBackground } from '../assets/js/lib/three-vignette.js';

import { environments } from '../assets/environment/index.js';





// import * as THREE from 'three';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

// window['THREE'] = THREE;
export class AppComponent implements OnInit {

  title = 'Pabst POC';

  public webcamAccess: boolean = false;

  scene;
  camera;
  // renderer;
  clock;
  deltaTime;
  totalTime;

  arToolkitSource;
  arToolkitContext;
  markerControls;

  markerRoot1;
  pmremGenerator;
  vignette;

  mesh1;
  numRenders = 0;

  _MTLLoader;
  showPBR = false;
  artistData;
  artistIdx = 0;
  canMesh;
  canObject;
  canGroup;


  // @ViewChild('rendererContainer', { static: false}) rendererContainer: ElementRef;

  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true
  });
  // scene = null;
  // camera = null;
  mesh = null;

  constructor() {
    // if (!Detector.webgl) Detector.addGetWebGLMessage();


  }

  ngOnInit(): void {



    this.scene = new THREE.Scene();

    // const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
    // this.scene.add(ambientLight);

    this.camera = new THREE.Camera();
    this.scene.add(this.camera);

    // this.renderer = window.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.renderer.setClearColor(0xcccccc, 0);
    this.renderer.setSize(640, 480);
    this.renderer.domElement.style.position = 'absolute';
    this.renderer.domElement.style.top = '0px';
    this.renderer.domElement.style.left = '0px';

    this.renderer.gammaInput = false;
    this.renderer.gammaOutput = true;
    document.body.appendChild(this.renderer.domElement);


    this.pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    // this.vignette = createBackground({
    //   aspect: this.camera.aspect,
    //   grainScale: 0.001, // mattdesl/three-vignette-background#1
    //   colors: [0xffffff, 0xffffff]
    // });
    // this.vignette.name = 'Vignette';
    // this.vignette.renderOrder = -1;
    // this.scene.add(this.vignette);

    this.clock = new THREE.Clock();
    this.deltaTime = 0;
    this.totalTime = 0;

    this.arToolkitSource = new THREEx.ArToolkitSource({
      sourceType: 'webcam',
    });


    this.arToolkitSource.init(() => this.onResize());

    // handle resize event
    window.addEventListener('resize', () => this.onResize);

    this.arToolkitContext = new THREEx.ArToolkitContext({
      cameraParametersUrl: 'assets/data/camera_para.dat',
      detectionMode: 'mono'
      // detectionMode: 'color'
      // detectionMode: 'color_and_matrix'
    });

    this.arToolkitContext.init(() => this.arToolkitCompleted());


    ////////////////////////////////////////////////////////////
    // setup markerRoots
    ////////////////////////////////////////////////////////////

    // build markerControls
    this.markerRoot1 = new THREE.Group();
    this.scene.add(this.markerRoot1);
    this.markerControls = new THREEx.ArMarkerControls(this.arToolkitContext, this.markerRoot1, {
      type: 'pattern', patternUrl: 'assets/data/pattern-pabst-blue-ribbon.patt',
    });

    console.log('markerControls', this.markerControls)

    const light1 = new THREE.AmbientLight(0xffffff, .3);
    light1.name = 'ambient_light';
    this.camera.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 2.5);
    // light2.position.set(0.5, 0, 0.866); // ~60º
    light2.position.set(0, 50, 300); // ~60º
    light2.name = 'main_light';
    this.camera.add(light2);

    var hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 300, 100);
    this.markerRoot1.add(hemiLight);

    // var dirLight = new THREE.DirectionalLight(0xffffff);
    // dirLight.position.set(75, 50, 300);
    // this.markerRoot1.add(dirLight);

    // var dirLight2 = new THREE.DirectionalLight(0xffffff);
    // dirLight2.position.set(75, 300, 300);
    // this.markerRoot1.add(dirLight2);

    // const g1 = new THREE.CubeGeometry(1, 1, 1);
    // const m1 = new THREE.MeshNormalMaterial({
    //   transparent: true,
    //   opacity: 0.5,
    //   side: THREE.DoubleSide
    // });

    // this.mesh1 = new THREE.Mesh(g1, m1);
    // this.mesh1.position.y = 0.5;

    // this.markerRoot1.add(this.mesh1);




    // 3D model
    const geometry1 = new THREE.PlaneBufferGeometry(1, 1, 4, 4);
    const loader = new THREE.TextureLoader();

    const material1 = new THREE.MeshBasicMaterial({ color: 0x000000, opacity: .7 });
    this.mesh1 = new THREE.Mesh(geometry1, material1);
    this.mesh1.rotation.x = -Math.PI / 2;
    this.markerRoot1.add(this.mesh1);

    // this._MTLLoader = new THREE.MTLLoader()
    //   .setPath('assets/models/pbr/')
    //   .setTexturePath('assets/models/pbr/Textures/')
    //   .load('PBR_Can.mtl', (materials) => this.loadMTL(materials));
    // this._MTLLoader = new THREE.MTLLoader()
    //   .setPath('assets/models/')
    //   .load('fish-2.mtl', (materials) => this.loadMTL(materials));


    // this.animate();
    // this.loadFBX();
    this.loadTextures();
    this.loadGLTF();
    this.updateEnvironment();

    console.log('mesh1', this)

    this.cameraAccess();
    this.markerControls.addEventListener('markerFound', this.markerFound);
  }

  markerFound(event) {

    console.log('markerFound');

  }

  cameraAccess() {
    let navCheck: any = window.navigator;
    // navCheck.getMedia = (navCheck.getUserMedia || navCheck.webkitGetUserMedia || navCheck.mozGetUserMedia || navCheck.msGetUserMedia);

    navCheck.mediaDevices.getUserMedia({ video: true }).then(() => {
      // alert("WE HAVE IT.")
      this.webcamAccess = true;
      // this.checkCameraAccess();
    }, () => {
      this.webcamAccess = false;
      this.checkCameraAccess();
    });
  }

  checkCameraAccess() {
    setTimeout(() => {
      this.cameraAccess();
    }, 100)
  }

  onProgress(xhr) { console.log((xhr.loaded / xhr.total * 100) + '% loaded'); }
  onError(xhr) { console.log('An error happened'); }

  loadObj(group) {
    // Depricated
    console.log('group', group);
    this.canObject = group.children[0];
    this.canObject.material.side = THREE.DoubleSide;
    // this.canObject.position.y = 1;
    this.canObject.position.y = 0.25;
    // this.canObject.rotation.x = -1.74;
    // this.canObject.rotation.z = -1.74;
    this.canObject.scale.set(0.25, 0.25, 0.25);
    this.markerRoot1.add(this.canObject);
  }

  arToolkitCompleted() {
    this.camera.projectionMatrix.copy(this.arToolkitContext.getProjectionMatrix());
  }

  // loadMTL(materials) {
  //   materials.preload();
  //   new THREE.OBJLoader()
  //     .setMaterials(materials)
  //     .setPath('assets/models/')
  //     .load('fish-2.obj', (group) => this.loadObj(group), (xhr) => this.onProgress(xhr), (xhr) => this.onError(xhr));
  // }


  loadFBX() {
    var loader = new THREE.FBXLoader();
    loader.load('assets/models/pbr/Pabst_Can_Embeded.fbx', function (object) {
      // loader.load('assets/models/pbr/Pabst_Can.fbx', function (object) {
      this.canObject = object;
      console.log('this.canObject', this.canObject);

      // mixer = new THREE.AnimationMixer( object );

      // var action = mixer.clipAction( object.animations[ 0 ] );
      // action.play();
      this.
        this.canObject.receiveShadow = true;
      this.canObject.castShadow = true;
      this.canObject.traverse(function (child) {

        if (child.isMesh) {
          child.material.depthWrite = !child.material.transparent;
          // child.castShadow = true;
          // child.receiveShadow = true;
        }
      });
      var fbxScale = .1;
      this.canObject.scale.x = fbxScale;
      this.canObject.scale.y = fbxScale;
      this.canObject.scale.z = fbxScale;
      // this.canObject.position.set(0, 0, 0);
      this.markerRoot1.add(this.canObject);
      // hero.add(outlineMesh);
      // var newQueen = object.clone();
      // newQueen.position.set(500, 0, 0);
      // scene.add(newQueen);

    });
  }

  loadTextures() {

    var textureLoader = new THREE.TextureLoader();
    this.artistData = [{ artist: 'Super Cool Artist', label: 'Label', url: 'jerod_davies-1-1', mat: new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide }) },
    { artist: 'Super Cool Artist', label: 'Label', url: 'kelly_ward-1-1', mat: new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide }) },
    { artist: 'Super Cool Artist', label: 'Label', url: 'mcmonster-1', mat: new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide }) },
    { artist: 'Super Cool Artist', label: 'Label', url: 'trap_bob-1-1', mat: new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide }) },
    { artist: 'Super Cool Artist', label: 'Label', url: 'HoudiniCan', mat: new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide }) }];
    this.artistData.forEach(art => {
      art.mat.map = textureLoader.load('assets/models/pbr/glTF/textures/v2/' + art.url + '.jpg');
      art.mat.map.flipY = false;
    });
  }
  loadGLTF() {
    const loader = new THREE.GLTFLoader();
    this.canGroup = new THREE.Group();
    this.canGroup.rotation.x = -.8;
    this.markerRoot1.add(this.canGroup);
    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    const dracoLoader = new THREE.DRACOLoader();
    dracoLoader.setDecoderPath('/examples/js/libs/draco/');
    loader.setDRACOLoader(dracoLoader);

    this.canMesh = new THREE.MeshStandardMaterial({ map: null, side: THREE.DoubleSide })
    // Load a glTF resource
    loader.load(
      // resource URL
      // 'assets/models/pbr/glTF/PBR_Can.glb',
      'assets/models/pbr/glTF/PBR_Can.gltf',
      // called when the resource is loaded
      (gltf) => {
        console.log('this', this, gltf.scene);

        this.canObject = gltf.scene
        this.canGroup.add(this.canObject);
        console.log('da Can', this.canObject);
        // this.markerRoot1.add(gltf.scene);
        this.markerRoot1.rotation.y = -.5;
        this.canObject.traverse(function (child) {
          // console.log(child);

          if (child.isMesh) {
            console.log('child.material', child.material)
            // this.canMesh = child;
            // if (child.material) {
            //   console.log('this.canMesh', this.canMesh)
            // }


            // child.material.metalness = 0;
            //   child.castShadow = true;
            //   child.receiveShadow = true;
          }
        });
        // gltf.scene.children[0].material.metalness = 0;
        // gltf.scene.children[1].material.metalness = 0;


        // gltf.animations; // Array<THREE.AnimationClip>
        // gltf.scene; // THREE.Group
        // gltf.scenes; // Array<THREE.Group>
        // gltf.cameras; // Array<THREE.Camera>
        // gltf.asset; // Object

      },
      // called while loading is progressing
      (xhr) => {

        console.log((xhr.loaded / xhr.total * 100) + '% loaded');

      },
      // called when loading has errors
      (error) => {

        console.log('An error happened', error);

      }
    );
  }

  updateEnvironment() {

    const environment = environments.filter((entry) => entry.id === 'venice-sunset')[0];
    console.log('environment', environment, environments);

    this.getCubeMapTexture(environment).then(({ envMap }) => {

      //  this.scene.add(this.vignette);
      this.scene.environment = envMap;
      // this.scene.background = this.state.background ? envMap : null;
    });

  }

  getCubeMapTexture(environment) {
    const { path } = environment;

    // no envmap
    if (!path) return Promise.resolve({ envMap: null });

    return new Promise((resolve, reject) => {

      new THREE.RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load(path, (texture) => {

          const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
          this.pmremGenerator.dispose();

          resolve({ envMap });

        }, undefined, reject);

    });

  }


  update() {
    // update artoolkit on every frame
    // console.log('this.arToolkitSource.ready', this.arToolkitSource);
    // console.log('markerControls', this.markerControls.object3d.visible);

    if (this.arToolkitSource.ready !== false) {
      this.markerRoot1.rotation.x += 0.01;
      // console.log('mesh1');
      // this.mesh1.rotation.y += 0.02;
      this.arToolkitContext.update(this.arToolkitSource.domElement);
      // console.log('this.markerControls.object3d.visible', this.markerControls.object3d.visible);
      if (this.markerControls.object3d.visible) {
        // console.log('visible!');
        this.showFirstCan();
        if (this.canObject) {
          // console.log('this.canObject', this.canObject);
          this.canObject.rotation.y += .01;
        }
      } else {
        // console.log('this.showPBR = false;');
        this.showPBR = false;
      }
    } else if (this.arToolkitSource.ready == false) {
    }
  }

  showFirstCan() {
    if (!this.showPBR) {
      this.showPBR = true;
      const sc = 8;
      this.canObject.scale.set(0, 0, 0);
      TweenMax.to(this.canObject.scale, .5, { x: sc, y: sc, z: sc, onUpdate: this.onFishUpdate });
      // this.animatePBRCan();
    }
  }
  animatePBRCan(bLeft = true) {
    const sc = 8;
    // this.canObject.scale.set(sc, sc, sc);

    // this.canObject.position.set(0, -1, 0);
    // TweenMax.to(this.canObject.position, .5, { x: 0, y: 0, z: 0, onUpdate: this.onFishUpdate });

    // this.canObject.position.set(100, -1, 0);
    TweenMax.to(this.canObject.position, .5, { x: 5 * ((bLeft) ? -1 : 1), y: 0, z: 0, onComplete: this.onCanLeave.bind(this), onCompleteParams: [this, bLeft] });

  }

  onCanLeave(root, bLeft) {
    // Change Texture. 
    // this.changeCan(this);
    let set
    this.canObject.traverse((child) => {
      if (child.isMesh) {
        if (child.material.name === 'Label') {
          console.log('child.material', child)
          // child.material = this.artistData[this.artistIdx].mat;
          child.material.map = this.artistData[this.artistIdx].mat.map;
          child.material.needsUpdate = true;
        }
        // break;
      }
    });
    this.canObject.position.set(-5 * ((bLeft) ? -1 : 1), 0, 0);
    TweenMax.to(this.canObject.position, .5, { x: 0, y: 0, z: 0 });

  }
  changeCan(this) {
    this.canObject.traverse(function (child) {
      // console.log(child);

      if (child.isMesh) {
        console.log('child.material', child)
        child.material.map = this.artistData[this.artistIdx];
      }
    });
  }

  onFishUpdate() {
    console.log("we are updating the fish.")
  }


  render() {
    this.renderer.render(this.scene, this.camera);
  }


  animate() {
    this.numRenders++;
    if (this.numRenders > 200) {
      // return;
    }
    window.requestAnimationFrame(() => this.animate());
    // requestAnimationFrame(() => this.animate());
    this.deltaTime = this.clock.getDelta();
    this.totalTime += this.deltaTime;
    this.update();
    this.render();
  }
  ngAfterViewInit() {
    // this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.animate();
    console.log('Init');
  }

  onResize() {
    this.arToolkitSource.onResizeElement();
    this.arToolkitSource.copyElementSizeTo(this.renderer.domElement);
    if (this.arToolkitContext.arController !== null) {
      this.arToolkitSource.copyElementSizeTo(this.arToolkitContext.arController.canvas);
    }
  }

  public moreArtists(): void {
    console.log('more artists?');
    window.open('https://pabstblueribbon.com/art/');
  }

  public arrowClick(dir: string) {
    if (dir === 'prev') {
      console.log('prev clicked!');
      this.artistIdx--;
      if (this.artistIdx < 0) {
        this.artistIdx = this.artistData.length - 1;
      }
      this.animatePBRCan();
    } else {
      console.log('next clicked!');
      this.artistIdx++;
      if (this.artistIdx > this.artistData.length - 1) {
        this.artistIdx = 0;
      }
      this.animatePBRCan(false);
    }
  }


  // animate() {
  // window.requestAnimationFrame(() => this.animate());
  // this.mesh.rotation.x += 0.01;
  // this.mesh.rotation.y += 0.02;
  // this.renderer.render(this.scene, this.camera);
  // }

}
